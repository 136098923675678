import React, { Component } from "react";
import "./App.css";
import styled, { keyframes } from "styled-components";
import { getMessages, getLogin } from "./components/api";
import glitches from "./images/glitches.gif";
import construction from "./images/construction.gif";
import { NoiseAnim, NoiseAnim2 } from "./components/noiseAnimations";
import LoadingFlicker from "./components/loadingFlicker";
import { submitCommand } from "./components/socketConnection";

const fadeIn = keyframes`
  0% {
    opacity: 0;
    transform: scale(0.9);
    filter: hue-rotate(250deg) blur(20px)
  }
  100% {
    opacity: 1;
    transform: scale(1);
    filter: hue-rotate(0deg) blur(0.1px)
  }
`;

const LogoMobile = styled.pre`
  font-size: 5px;
  margin-top: 40px;
  display: block;
  @media (min-width: 1000px) {
    display: none;
  }
`;

const Logo = styled.pre`
  animation: ${fadeIn} 2s ease-in forwards;
  position: relative;
  display: none;
  @media (min-width: 1000px) {
    display: block;
  }
  &:before {
    content: attr(data-text);
    position: absolute;
    left: calc(50% - 2px);
    transform: translateX(-50%);
    text-shadow: -1px 0 red;
    top: 0;
    color: white;
    background: black;
    overflow: hidden;
    clip: rect(0, 900px, 0, 0);
    animation: ${NoiseAnim} 2s infinite linear alternate-reverse;
  }
  &:after {
    content: attr(data-text);
    position: absolute;
    left: calc(50% - 2px);
    transform: translateX(-50%);
    text-shadow: 1px 0 blue;
    top: 0;
    color: white;
    background: black;
    overflow: hidden;
    clip: rect(0, 900px, 0, 0);
    animation: ${NoiseAnim2} 3s infinite linear alternate-reverse;
  }
`;

const GlitchWrap = styled.div`
  position: fixed;
  background-size: cover;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  mix-blend-mode: color;
  filter: hue-rotate(260deg);
  opacity: 0.2;
  pointer-events: none;
  display: none;
  @media (min-width: 1000px) {
    display: block;
    background-image: url(${glitches});
  }
`;

const Messages = styled.ul`
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  flex-wrap: wrap;
  margin-bottom: 40px;
  @media (min-width: 1000px) {
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
  }

  li {
    width: 300px;
    margin: 0 20px 0 20px;
    h3 {
      border-bottom: 2px solid var(--color-textcolor);
    }
  }
`;

const MessageWrapper = styled.div`
  max-width: 500px;
  margin: 0 auto 40px auto;
  padding: 20px;
  @media (min-width: 1000px) {
    padding: 0;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
  }
  filter: hue-rotate(60deg);
  .signature {
    text-align: right;
    font-weight: bold;
  }
`;

const MessageContainer = styled.li`
  @media (min-width: 1000px) {
    opacity: 0;
    filter: hue-rotate(250deg) blur(20px);
    animation: ${fadeIn} ${props => props.duration} linear forwards;
  }
  h3 {
    font-size: 22px;
    padding-bottom: 10px;
  }
`;

const AdminDialog = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  opacity: 0;
  transition: all 0.1s ease-in;
  &.show {
    opacity: 1;
    div {
      transform: translateY(0);
    }
  }
  div {
    background-color: black;
    box-shadow: 0 0 30px var(--color-textcolor);
    transform: translateY(20px);
    transition: all 0.2s 0.1s ease-in;
    border: 1px solid var(--color-textcolor);
    padding: 20px;
  }
`;

const LoginWrapper = styled.form`
  padding: 400px 20px 40px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 400px;
  margin: 0 auto;
  filter: hue-rotate(60deg);
  input {
    background-color: transparent;
    border: 1px solid var(--color-textcolor);
    padding: 20px;
    text-transform: uppercase;
    box-shadow: 0 0 30px var(--color-textcolor);
    font-family: Cutive Mono;
    color: var(--color-textcolor);
    font-size: 14px;
    &::placeholder {
      color: var(--color-textcolor);
    }
    margin-bottom: 10px;
    width: 100%;
    font-size: 16px;
  }
  button {
    background-color: var(--color-textcolor);
    border-radius: 2px;
    padding: 20px;
    text-transform: uppercase;
    font-weight: bold;
    font-family: Cutive Mono;
    color: black;
    font-size: 16px;
    width: 100%;
    border: 0;
    margin: 0;
    margin-top: 4px;
    box-shadow: 0 0 30px var(--color-textcolor);
    border-bottom: 6px solid var(--color-textcolor-dark);
    font-size: 14px;
  }
`;

class App extends Component {
  constructor(props) {
    super();

    this.state = {
      posts: [],
      input: "",
      showDialog: false,
      dialogMessage: "",
      dialogUsername: "",
      loading: false,
      exposedString: `
                                                                                          
                                                                                          
      /$$$$$$   /$$$$$$   /$$$$$$   /$$$$$$$  /$$$$$$   /$$$$$$  /$$   /$$ /$$$$$$$  /$$$$$$$ 
     /$$__  $$ /$$__  $$ /$$__  $$ /$$_____/ /$$__  $$ /$$__  $$| $$  | $$| $$__  $$| $$__  $$
    | $$  \\ $$| $$  \\ $$| $$  \\__/|  $$$$$$ | $$  \\ $$| $$  \\__/| $$  | $$| $$  \\ $$| $$  \\ $$
    | $$  | $$| $$  | $$| $$       \\____  $$| $$  | $$| $$      | $$  | $$| $$  | $$| $$  | $$
    | $$$$$$$/|  $$$$$$/| $$       /$$$$$$$/|  $$$$$$$| $$      |  $$$$$$/| $$  | $$| $$  | $$
    | $$____/  \\______/ |__/      |_______/  \\____  $$|__/       \\______/ |__/  |__/|__/  |__/
    | $$                                     /$$  \\ $$                                        
    | $$                                    |  $$$$$$/                                        
    |__/                                     \\______/                                         
                                                                                /$$                    
                                                                                | $$                    
                /$$$$$$  /$$   /$$  /$$$$$$   /$$$$$$   /$$$$$$$  /$$$$$$   /$$$$$$$                    
              /$$__  $$|  $$ /$$/ /$$__  $$ /$$__  $$ /$$_____/ /$$__  $$ /$$__  $$                    
              | $$$$$$$$ \\  $$$$/ | $$  \\ $$| $$  \\ $$|  $$$$$$ | $$$$$$$$| $$  | $$                    
              | $$_____/  >$$  $$ | $$  | $$| $$  | $$ \\____  $$| $$_____/| $$  | $$                    
              |  $$$$$$$ /$$/\\  $$| $$$$$$$/|  $$$$$$/ /$$$$$$$/|  $$$$$$$|  $$$$$$$                    
              \\_______/|__/  \\__/| $$____/  \\______/ |_______/  \\_______/ \\_______/                    
                                  | $$                                                                  
                                  | $$                                                                  
                                            |__/                                                                            
    
      `
    };
    this.handlePosts.bind(this);
  }

  handlePosts(posts) {
    posts = posts.reverse();
    this.setState({ posts: posts });
    console.log("lagt inn posts");
  }

  async componentWillMount() {
    let posts = await getMessages();
    this.handlePosts(posts);
  }

  async handleSubmit(event) {
    event.preventDefault();
    event.stopPropagation();
    console.log("logger inn med ", this.state.input);

    const login = await getLogin(this.state.input);
    if (login) {
      this.setState({
        loading: true
      });
      setTimeout(() => {
        this.setState({
          showDialog: true,
          dialogMessage: login.message,
          dialogUsername: login.username,
          loading: false
        });
      }, 3000);
    } else {
      window.alert("Feil brukærnavn. Prøværu å hacke meg eller?");
    }
    submitCommand(this.state.input);
  }

  handleInput(event) {
    this.setState({ input: event.target.value });
  }

  render() {
    let exposedString = this.state.exposedString;
    let posts = this.state.posts;
    let showDialog = this.state.showDialog;
    let dialogUsername = this.state.dialogUsername;
    let dialogMessage = this.state.dialogMessage;
    let loading = this.state.loading;
    return (
      <div className="App">
        <GlitchWrap />
        <LogoMobile>{exposedString}</LogoMobile>
        <Logo data-text={exposedString}>{exposedString}</Logo>
        <MessageWrapper>
          <h2>Kjære Porsgrunn undergrunn</h2>
          <p>
            Er dere lei av synlige ordførere, porselen til alle måltider, og alt
            for godt stekte chips på Barry Bs? Denne påsken skal vi snu opp ned
            på <strong>alt</strong>.
          </p>
          <p>
            Hver dag i påska skal jeg lekke sentrale e-poster fra ansatte på
            Porsgrunn rådhus. Ikke prøv å stopp meg!
          </p>
          <p>
            Men om <strong>du</strong> tilfeldigvis skulle ville prøve å stoppe
            meg - og du er en hacker med skillz - vil jeg hver dag lekke hint
            som jeg har gjemt på PorsgrunnZone.
          </p>
          <p>
            Klarer du å bruke hintene til å logge inn på Rådhuset sin filserver,
            og skrive ordet "troll", vil du sperre tilgangen for meg, og
            forhindre katastrofen. Men det klaræru ikke.
          </p>
          <p className="signature">-Den store TZ</p>
        </MessageWrapper>
        <div className="messages">
          <Messages>
            {posts.map((post, x) => (
              <MessageContainer key={x} duration={`${x + 1}s`}>
                <h3>{post.title}</h3>
                {post.message.map((message, y) => (
                  <p key={y}>{message}</p>
                ))}
              </MessageContainer>
            ))}
          </Messages>
        </div>
        <LoginWrapper onSubmit={event => this.handleSubmit(event)}>
          <h2>Logg Inn</h2>
          <input
            type="text"
            onChange={event => this.handleInput(event)}
            placeholder="ditt fulle navn..."
          />
          <button type="submit">Logg inn</button>
        </LoginWrapper>

        <div
          style={{
            opacity: loading ? "1" : "0",
            pointerEvents: "none",
            position: "fixed",
            left: 0,
            top: 0,
            width: "100%",
            height: "100%"
          }}
        >
          <LoadingFlicker />
        </div>

        <AdminDialog className={`${showDialog ? "show" : ""}`}>
          <div className="inner-dialog">
            <h3>{dialogMessage}</h3>
            <p>Logget inn som: {dialogUsername}</p>
            <p>***************</p>
            <p>Admin er under construction</p>
            <p>
              <img alt="construction" src={construction} />
            </p>
          </div>
        </AdminDialog>
      </div>
    );
  }
}

export default App;
