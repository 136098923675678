import { keyframes } from "styled-components";

const NoiseAnim = keyframes`
  0% {
    clip: rect(70px, 9999px, 98px, 0);
  }
  5% {
    clip: rect(29px, 9999px, 28px, 0);
  }
  10% {
    clip: rect(33px, 9999px, 19px, 0);
  }
  15% {
    clip: rect(96px, 9999px, 11px, 0);
  }
  20% {
    clip: rect(47px, 9999px, 53px, 0);
  }
  25% {
    clip: rect(19px, 9999px, 9px, 0);
  }
  30% {
    clip: rect(27px, 9999px, 36px, 0);
  }
  35% {
    clip: rect(14px, 9999px, 84px, 0);
  }
  40% {
    clip: rect(28px, 9999px, 4px, 0);
  }
  45% {
    clip: rect(66px, 9999px, 53px, 0);
  }
  50% {
    clip: rect(83px, 9999px, 87px, 0);
  }
  55% {
    clip: rect(72px, 9999px, 8px, 0);
  }
  60% {
    clip: rect(34px, 9999px, 30px, 0);
  }
  65% {
    clip: rect(65px, 9999px, 85px, 0);
  }
  70% {
    clip: rect(19px, 9999px, 31px, 0);
  }
  75% {
    clip: rect(30px, 9999px, 80px, 0);
  }
  80% {
    clip: rect(6px, 9999px, 31px, 0);
  }
  85% {
    clip: rect(3px, 9999px, 39px, 0);
  }
  90% {
    clip: rect(58px, 9999px, 95px, 0);
  }
  95% {
    clip: rect(46px, 9999px, 35px, 0);
  }
  100% {
    clip: rect(48px, 9999px, 39px, 0);
  }
`;

const NoiseAnim2 = keyframes`
  0% {
    clip: rect(36px, 9999px, 40px, 0);
  }
  5% {
    clip: rect(43px, 9999px, 39px, 0);
  }
  10% {
    clip: rect(28px, 9999px, 65px, 0);
  }
  15% {
    clip: rect(8px, 9999px, 20px, 0);
  }
  20% {
    clip: rect(31px, 9999px, 98px, 0);
  }
  25% {
    clip: rect(51px, 9999px, 41px, 0);
  }
  30% {
    clip: rect(72px, 9999px, 58px, 0);
  }
  35% {
    clip: rect(80px, 9999px, 89px, 0);
  }
  40% {
    clip: rect(72px, 9999px, 68px, 0);
  }
  45% {
    clip: rect(43px, 9999px, 42px, 0);
  }
  50% {
    clip: rect(6px, 9999px, 10px, 0);
  }
  55% {
    clip: rect(18px, 9999px, 81px, 0);
  }
  60% {
    clip: rect(20px, 9999px, 68px, 0);
  }
  65% {
    clip: rect(13px, 9999px, 88px, 0);
  }
  70% {
    clip: rect(39px, 9999px, 85px, 0);
  }
  75% {
    clip: rect(26px, 9999px, 43px, 0);
  }
  80% {
    clip: rect(67px, 9999px, 68px, 0);
  }
  85% {
    clip: rect(85px, 9999px, 99px, 0);
  }
  90% {
    clip: rect(79px, 9999px, 86px, 0);
  }
  95% {
    clip: rect(70px, 9999px, 58px, 0);
  }
  100% {
    clip: rect(33px, 9999px, 75px, 0);
  }
`;

export { NoiseAnim, NoiseAnim2 };
