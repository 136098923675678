let apiUrl = "";

if (process.env.NODE_ENV === "production") {
  apiUrl = "https://paaske2019.kode24.no";
}

const getMessages = async () => {
  const response = await tryWebCall(
    apiUrl + "/api/exposed/messages",
    "get",
    {}
  );
  return response;
};

const getLogin = async username => {
  console.log("logger inn med ", username);
  const response = await tryWebCall(apiUrl + "/api/exposed/login", "post", {
    username: username
  });
  return response;
};

const tryWebCall = async (url, method, data) => {
  try {
    let response = undefined;
    if (method === "post") {
      response = await fetch(url, {
        method: method,
        credentials: "omit",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(data)
      });
    } else {
      response = await fetch(url);
    }

    if (response.ok) {
      const json = await response.json();
      return json;
    } else {
      throw new Error("404");
    }
  } catch (error) {
    console.log(error);
    return false;
  }
};

export { getMessages, getLogin };
